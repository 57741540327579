.quick-booker-container {
    position: relative;
}
.quick-booker {
    position: absolute;
    top: 300px;
    right: 60px;
    background: white;
    z-index: 660;
    padding: 35px;
    box-shadow: 0 0 35px rgba(0,0,0,0.2);
    opacity: 0;
    transition: opacity .3s ease;

    @media screen and (max-width: 1300px){
        top: 325px;
        right: 0;
        padding: 30px;
        transform: scale(0.8);
    }
    @include breakpoint(mobile){
        transform: scale(1);
        left: 20px;
        right: 20px;
    }

    h3 {
        margin: 0 0 20px;
    }

    .close-quickbooker-wrapper {
        display: none;
    }

    .toggle-actions {
        display: flex;
        border: 1px solid $darkGrey;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        min-width: 350px;
        margin-bottom: 10px;

        @include breakpoint(tablet){
            min-width: 0;
        }

        button {
            height: 40px;
            box-shadow: none;
            outline: none;
            background: rgba($grey, 0.5) url('../img/icon-check.svg') no-repeat 15px -50%;
            background-size: 13px 10px;
            color: rgba($black, 0.5);
            font-size: 0.875em;
            transition: all .3s;
            border: 0;
            padding: 5px 15px;
            text-align: left;
            white-space: nowrap;
            cursor: pointer;
            font-family: $base-font-family;
            width: 50%;

            @include breakpoint(tablet){
                font-size: 1em;
                width: 60%;
                &:first-child {
                    width: 40%;
                }
            }

            &.active {
                background-color: white;
                background-position: 15px 53%;
                color: $black;
                padding-left: 40px;
                width: 60%;
                @include breakpoint(tablet) {
                    width: 70%;
                }
            }
            &:nth-child(2){
                border-left: 1px solid $grey;
            }
        }
    }

    select {
        font-size: 0.875em;
        @include breakpoint(mobile){
            font-size: 1em;
        }
    }

    &._scrolled {
        position: fixed;
        top: auto !important;
        bottom: -60px;
        left: 0;
        width: 100%;
        height: 60px;
        animation: quickBookerSlideIn .3s ease-out;
        animation-fill-mode: both;
        padding: 0;
        display: flex;
        align-items: center;
        transform: scale(1);

        @include breakpoint(tablet){
            display: block;
            text-align: center;
            &._open {
                height: auto;
                bottom: 0;
                animation: none;

                h3 {
                    display: none !important;
                }
                .close-quickbooker-wrapper {
                    display: block;
                    margin-bottom: 15px;
                }
            }
        }

        .inner {
            max-width: 900px;
            margin: 0 auto;

            h3 {
                display: none;
                @include breakpoint(tablet){
                    display: inline-block;
                    color: white;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 5px;
                    background: $black;
                    cursor: pointer;
                    margin: 10px auto;
                }
            }


            form {
                display: flex;
                align-items: center;

                .parsley-errors-list {
                    display: none !important;
                }

                @include breakpoint(tablet){
                    flex-direction: column;
                    padding: 15px;
                    .actions {
                        width: 100%;
                    }
                }

                .toggle-actions {
                    margin: 0 15px 0 0;
                    width: 200px;
                    @include breakpoint(tablet){
                        width: 100%;
                        margin: 0 0 5px;
                    }
                }

                select,
                .input-group {
                    margin: 0;
                    height: 40px;
                }
                .button {
                    margin-left: 15px;
                    height: 40px;
                    padding-top: 11px;
                    padding-bottom: 10px;
                    &:after {
                        padding-top: 11px;
                        padding-bottom: 10px;
                    }
                    @include breakpoint(tablet){
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

@include keyframes(quickBookerSlideIn){
    0% {
        bottom: -60px;
    }
    100% {
        bottom: 0;
    }
}