.button {
	width: auto;
	height: auto;
	border: none;
	background-color: white;
	color: $black;
	border-radius: 5px;
	transition: all .3s;
	outline: none;
	display: inline-block;
	padding: 15px 20px 13px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: none;
	text-transform: uppercase;
	font-size: 1em;
	font-family: $heading-font-family;
	text-decoration: none;
	overflow: hidden;
	position: relative;
	transition: all .3s;
	cursor: pointer;

	&[disabled] {
		opacity: 0.5;
		cursor: progress;
	}

	&._loading {
		padding-right: 40px;
		background-image: url('../img/loading.svg');
		background-repeat: no-repeat;
		background-position: right 15px center;
		background-size: 15px 15px;
	}

	&:after {
		content: attr(data-text);
		color: white;
		position: absolute;
		padding: 15px 20px;
		width: calc(100% - 39px);
		top: 0;
		left: 0;
		opacity: 0;
		-webkit-transform: translate(0, 30%);
		transform: translate(0, 30%);
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	&:hover {
		background-color: $base-color2;
		color: white;
		@include breakpoint(tablet-and-above){
			&:after {
				opacity: 1;
				-webkit-transform: translate(0, 0);
				transform: translate(0, 0);
			}
		}
	}

	&:active {
		text-decoration: none;
		transform: scale(0.95);
	}
}

.button.outline {
	background-color: transparent;
	border: 1px solid $black;
	color: $black;
	&:hover {
		background-color: $black;
	}

	&.white {
		border-color: white;
		color: white;
		&:hover {
			background-color: white;
			color: black;
		}
	}
}



.button._secondary {
	background-color: $black;
	color: white;
	&:hover {
		background-color: $base-color2;
	}

}