.content {
    #rm_reservation {
        width: 100%;
        float: none;
        font-family: inherit;
        text-align: center;

        .rm_reservation_top,
        .rm_reservation_body,
        .rm_reservation_bottom {
            width: 100%;
        }

        .rm_header,
        .rm_box_wrapper {
            text-align: center;
        }

        #rm_wrapper .rm_box_wrapper #rm_search_box .rm_box_row .rm_row_value input, #rm_wrapper .rm_box_wrapper #rm_search_box .rm_box_row .rm_row_value select {
            height: auto;
        }
    }
}