/**
 * Sitekick overwriting
 */

$sitekickBarHeight: 70px;

body.sitekick {
    padding-top: 80px + $sitekickBarHeight;

    .main-header {
        top: $sitekickBarHeight;
    }

    a[href^="tel"]{
        color: inherit;
    }

    .section-contact {
        .sitekick-upload-container .single-upload-container .images-list .thumbnail {
            min-height: 0;
        }
    }

    .header-container .header-content .container .header-text-1 {
        right: 0;
    }
    .header-container .header-content .container .header-text-2 {
        left: 0;
    }

    .header-container .inline-edit-controls {
        left: 0 !important;
    }


    .inline-edit * {
        opacity: 1 !important;
    }
}