body._overlay {
    overflow: hidden;
}

.popup-wrapper {
    -webkit-transform: translate3d(0,0,0); // fix for safari
    position: fixed;
    top: 0; left: 0;
    z-index: 666;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(0,0,0, 0.5);
    padding: 0 60px;
    @include breakpoint(mobile){
        padding: 0;
    }

    &._hide {
        @extend .animated;
        @extend .fast;
        @extend .fadeOut;
        @extend .goAway;


        .popup {
            @extend .bounceOut;
            @extend .goAway;
        }

    }

    .popup {
        max-width: 1500px;
        margin: 120px auto 30px;
        background: white;

        @include breakpoint(mobile){
            margin: 15px;
        }

        @extend .animated;
        @extend .fast;
        @extend .fadeInDown;
        @extend .go;

        .inner {
            display: flex;
            @include breakpoint(mobile){
                flex-direction: column;
                flex-flow: column-reverse;
            }


            .image {
                width: 50%;
                flex-shrink: 0;
                @include breakpoint(mobile){
                    width: 100%;
                }
                img {
                    display: block;
                }
            }
            .body {
                width: 100%;
                position: relative;
                padding: 100px 80px;

                @include breakpoint(mobile){
                    padding: 90px 30px 30px;
                }

                h1 {
                    color: $black;
                    font-size: 42px;
                    line-height: 50px;
                    @include breakpoint(mobile){
                        font-size: 30px;
                        line-height: 35px;
                    }
                }

                h2 {
                    color: $green;
                    font-size: 24px;
                    line-height: 26px;
                    margin-bottom: 0;
                    @include breakpoint(mobile){
                        font-size: 22px;
                        line-height: 24px;
                    }
                }

                h2+h1 {
                    margin-top: 15px;
                }

                .btn-popup-close {
                    background: #e7ebec;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 60px;
                    height: 60px;
                    transition: background-color 150ms ease-out;
                    border: 0;
                    font-size: 0;
                    outline: none;
                    cursor: pointer;

                    &:hover {
                        background-color: $base-color2;
                        &:before, &:after {
                            background: white;
                        }
                    }
                    &:before {
                        content: "";
                        display: block;
                        width: 40px;
                        height: 1px;
                        background: $black;
                        transform: rotate(45deg) translateX(4px) translateY(-2px);
                    }

                    &:after {
                        content: "";
                        display: block;
                        width: 40px;
                        height: 1px;
                        background: $black;
                        transform: rotate(-45deg) translateX(2px) translateY(3px);
                    }
                }
            }
        }
    }

    /* hide */
    &._hidden {
        display: none;
    }
}