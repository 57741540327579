// Import plugins
@import "plugins/animations";
@import "plugins/bootstrap";
@import "plugins/animate";

// Import mixins
@import "mixins/abs-pos";
@import "mixins/border-radius";
@import "mixins/breakpoints";
@import "mixins/keyframes";
@import "mixins/transition";
@import "mixins/gutter";
@import "mixins/shorthands";
@import "mixins/flex";
@import "mixins/center";

// Import settings
@import "settings/colors";
@import "settings/font";
@import "settings/links";

// Box sizing
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

// Body
body {
	background-color: 	$body-bg-color;
	font-family: 		$base-font-family;
	font-size: 			$base-font-size;
	line-height:		1.5em;
	font-weight: 		$base-font-weight;
	color: 				$base-font-color1;
	margin: 			0;
	padding: 			0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media screen and (max-width: 1300px){
		font-size: $base-font-size * 0.875;
	}
}

// Import elements
@import "elements/button";
@import "elements/table";
@import "elements/forms";
@import "elements/map";
@import "elements/fancyoverlay";

// Headings
h1,h2,h3,h4,h5,h6 {
	font-family: 		$heading-font-family;
	font-weight: 		$heading-font-weight;
	line-height:		1.5em;
	color: 				$heading-font-color1;
}

h1 {
	font-size: $heading-h1-size;
	@media screen and (max-width: $laptop){
		font-size: $heading-h1-size * 0.75;
	}
}
h2 {
	font-size: $heading-h2-size;
	@media screen and (max-width: $laptop){
		font-size: $heading-h1-size * 0.75;
	}
}
h3 {
	font-size: $heading-h3-size;
	@media screen and (max-width: $laptop){
		font-size: $heading-h1-size * 0.75;
	}
}
h4 {
	font-size: $heading-h4-size;
	@media screen and (max-width: $laptop){
		font-size: $heading-h1-size * 0.75;
	}
}
h5 {
	font-size: $heading-h5-size;
	@media screen and (max-width: $laptop){
		font-size: $heading-h1-size * 0.75;
	}}
h6 {
	font-size: $heading-h6-size;
	@media screen and (max-width: $laptop){
		font-size: $heading-h1-size * 0.75;
	}
}

// Links
a {
	// Default
	color: $link-color-default;
	text-decoration: $link-decoration-default;

	// Hover
	&:hover {
		color: $link-color-hover;
		text-decoration: $link-decoration-hover;
	}

	// Active
	&:active {
		color: $link-color-active;
		text-decoration: $link-decoration-active;
	}

	// Visited
	&:visited {
		color: $link-color-visited;
		text-decoration: $link-decoration-visited;
	}
}

// Responsive video iframes
.video-container {
	position: relative;
	padding-bottom: 56.25%; /*16:9*/
	height: 0;
	overflow: hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Img
img {
	max-width: 100%;
}

// Clearspace
._clearspace {
	padding: 0!important;
	margin: 0!important;
}