/**
 * Interactive map styling
 */

.interactive-map {
    position: relative;
    font-size: 1em;
    min-height: 80vh;
    background: url('../img/loading-brown.svg') no-repeat center 150px;
    background-size: 50px 50px;

    @include breakpoint(tablet){
        display: none;
    }

    ._loading.container {
        min-height: 80vh;
    }

    &._initialized {
        min-height: 0;
        ._loading {
            display: none;
        }
    }

    .interactive-map-background {
        opacity: 0;
        transition: opacity .5s ease-out;
        img {
            width: 100%;
            height: auto;
        }
    }

    .interactive-map-overlay {
        opacity: 0;
        transition: opacity .5s ease-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .interactive-map-content {
            height: 100%;
            text-align: right;
            color: white;
            padding-top: 115px;
            border-left-color: rgba(255,255,255,.5);
            border-right-color: rgba(255,255,255,.5);

            h1, h2, h3 {
                color: white;
                em {
                    font-size: 80px;
                    font-style: normal;
                    display: inline-block;
                    padding: 10px;
                    color: #fff;
                    text-shadow: 0 0 10px #161616;
                }
                sup {
                    margin-bottom: -15px;
                    font-size: 45px;
                    line-height: 35px;
                    display: inline-block;
                    font-style: normal;
                    background: none;
                    padding: 10px;
                    color: #fff;
                    text-shadow: 0 0 10px #161616;
                }

            }
        }
    }

    // loaded animation
    &._initialized {
        .interactive-map-overlay,
        .interactive-map-background {
            opacity: 1;
        }

    }
}

// markers
.interactive-map-marker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    @extend .animated;
    @extend .fadeIn;
    @extend .go;

    .interactive-map-marker-pulse {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        * {
            cursor: pointer;
        }

        .pulser {
            position: relative;
            .small-dot {
                display: block;
                border-radius: 100%;
                background: #baa175;
                border: 2px solid #141414;
                width: 15px;
                height: 15px;
                position: relative;
                z-index: 2;
            }
            .big-dot {
                display: block;
                border-radius: 100%;
                background: rgba(186, 161, 117, .5);
                position: absolute;
                top: -12.5px;
                left: -12.5px;
                width: 40px;
                height: 40px;
                z-index: 1;


                animation-name: pulse;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-fill-mode: both;
            }
        }

        label {
            display: block;
            margin-top: 30px;
            background: $base-color1;
            color: white;
            text-transform: uppercase;
            padding: 15px;
            border-radius: 5px;
            font: 16px/16px $heading-font-family;
            @media screen and (max-width: 1445px){
                font-size: 12px;
                line-height: 12px;
                padding: 10px;
            }
        }
        &:hover label {
            background: $base-color2;
        }
    }

    .interactive-map-marker-tooltip {
        position: absolute;
        z-index: -100;
        top: -48px;
        background: white;
        padding: 15px;
        width: 460px;
        box-shadow: 0 0 50px $base-color1;
        visibility: hidden;
        opacity: 0;
        transform: rotate(10deg) translateY(-15px) translateX(15px);
        transform-origin: 100% 0;
        transition: all .2s ease-out;



        &:after {
            content: "";
            position: absolute;
            top: 40px;
            left: -15px;
            width: 0;
            height: 0;
            border-right: 15px solid #fff;
            border-bottom: 15px solid transparent;
            border-top: 15px solid transparent;
        }

        .interactive-map-marker-tooltip-close {
            position: absolute;
            z-index: 3;
            right: -15px;
            top: -15px;
            width: 30px;
            height: 30px;
            background: $base-color1;
            border-radius: 30px;
            color: white;
            text-align: center;
            font: 15px/30px $heading-font-family;
            outline: none;
            cursor: pointer;
            box-shadow: none;
            border: 0;
            &:hover {
                background-color: $base-color2;
            }
        }

        .interactive-map-marker-tooltip-inner {
            display: flex;
            .interactive-map-marker-tooltip-column {
                width: 50%;
            }
        }
        .interactive-map-marker-name {
            font: 20px/30px $heading-font-family;
            text-transform: uppercase;
            display: block;
            padding: 15px 15px 0;
            margin: 0;
        }
        .interactive-map-marker-content {
            font-size: 12px;
            line-height: 14px;
            padding: 0 15px;

            .button {
                @extend ._secondary;
            }
        }

        .interactive-map-marker-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
                width: 100%;
                display: block;
            }
        }
    }

    &._left .interactive-map-marker-tooltip {
        right: calc(50% + 30px);
        &:after {
            left: auto;
            right: -15px;
            border-left: 15px solid #fff;
            border-right: 0;
            border-bottom: 15px solid transparent;
            border-top: 15px solid transparent;
        }
    }
    &._right .interactive-map-marker-tooltip {
        left: calc(50% + 30px);
        transform-origin: 0 0;
        transform: rotate(-10deg) translateY(-15px) translateX(-15px);;
    }

    &._open {
        z-index: 10000;
    }
    &._open .interactive-map-marker-tooltip {
        visibility: visible;
        z-index: 100;
        opacity: 1;
        transform: rotate(0) translateY(0) translateX(0);
    }


}

@include keyframes( markerPulse ){
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

// mobile map
.mobile-map {
    width: auto;
    min-height: 500px;
    background: no-repeat top left $base-color1;
    background-size: cover;
    position: relative;
    margin: 30px 30px 0;
    display: none;
    @include breakpoint(tablet){
        display: block;
    }
    .overlay {
        position: absolute;
        width: 100%;
        bottom: 0; left: 0;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        padding: 30px;
        color: white;
        h1, h2, h3, h4 {
            margin: 0;
            color: white;
        }
    }
}