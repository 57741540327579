#fancyoverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #000;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;

  &.transformfullwidth {width: 100vw!important;height: 100vh;}

  .fancyoverlay-close {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 99;

    &:before {
      content: "";
      position: absolute;
      width: 0px;
      height: 1px;
      background: #FFF;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    &:after {
      content: "";
      position: absolute;
      width: 0px;
      height: 1px;
      background: #FFF;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    &.transform {
      &:before {
        -webkit-animation-name: fancyclose;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-delay: 0.7s;
        -webkit-animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
        animation-name: fancyclose;
        animation-duration: 0.5s;
        animation-delay: 0.7s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
      }
      &:after {
        -webkit-animation-name: fancyclose2;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-delay: 0.7s;
        -webkit-animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
        animation-name: fancyclose2;
        animation-duration: 0.5s;
        animation-delay: 0.7s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
      }
    }
  }

  .fancyoverlay-dialog {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: -webkit-calc(100% - 200px);
    width: calc(100% - 200px);
    height: -webkit-calc(100% - 200px);
    height: calc(100% - 200px);
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    @include breakpoint(mobile) {
      width: 100%;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 0px;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  &.transform {
    -webkit-animation-name: fancytransform;
    -webkit-animation-duration: 0.6s;
    -webkit-animation-timing-function: ease;
    //-webkit-animation-fill-mode: forwards;
    animation-name: fancytransform;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    //animation-fill-mode: forwards;
  }

  @-webkit-keyframes fancytransform {
    0%   {
      width: 0px;
      height: 5px;
    }
    50%  {
      width: 100vw;
      height: 5px;
    }
    100% {
      width: 100vw;
      height: 100vh;
    }
  }

  @keyframes fancytransform {
    0%   {
      width: 0px;
      height: 5px;
    }
    50%  {
      width: 100vw;
      height: 5px;
    }
    100% {
      width: 100vw;
      height: 100vh;
    }
  }

  @-webkit-keyframes fancyclose {
    0%   {
      width: 0px;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    50%  {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }

  @keyframes fancyclose {
    0%   {
      width: 0px;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    50%  {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }

  @-webkit-keyframes fancyclose2 {
    0%   {
      width: 0px;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    50%  {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
  }

  @keyframes fancyclose2 {
    0%   {
      width: 0px;
      height: 1px;
    }
    50%  {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
      width: 100%;
      height: 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
  }
}