.faq {
	.question {
		position: relative;
		margin-bottom: 15px;

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			border: 1px solid rgba(#000, 0.1);
			bottom : 0;
			left: 0;
		}

		h3 {
			width: 100%;
			color: #000 !important;
			font-size: 16px !important;
			padding: 0 50px 10px 0;
			margin: 0;
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				width: 16px;
				height: 1px;
				background: #000;
				right: 0;
				top: 8px;
			}

			&:after {
				content: '';
				position: absolute;
				width: 16px;
				height: 1px;
				background: #000;
				right: 0;
				transform: rotate(90deg);
				transition: all 0.3s ease;
				top: 8px;
			}
		}

		.answer {
			visibility: hidden;
			position: absolute;
			opacity: 0;
			height: 0;
			transition: height 0.3s ease, opacity 0.3s ease 0.1s;
		}

		&.open {

			h3 {
				&:after {
					transform: rotate(0deg);
				}
			}

			.answer {
				visibility: visible;
				position: relative;
				opacity: 1;
				height: auto;
				padding-bottom: 20px;
			}
		}
	}
}

.faq-list {
	padding: 50px 0;

	.faq {
		padding-bottom: 30px;

		.question {
			background: #FFF;
			padding: 0;
			margin-bottom: 10px;

			&:after {
				display: none;
			}

			h3 {
				padding: 17px 15px 15px 15px;

				&:before {
					top: 25px;
					right: 15px;
				}

				&:after {
					top: 25px;
					right: 15px;
				}
			}
		}

		.answer {
			padding: 10px 15px 20px 15px;

			p {
				padding: 0 0 15px 0;
				margin: 0;
			}
		}
	}
}