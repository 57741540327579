// Imports
$ultimateContainer: 1680px;
$laptop: 1300px;
$tablet: 1024px;
$pageBlockSpacing: 80px;
$headerHeight: 80px;

@font-face {
    font-family:"penumbra-sans-std";
    src:url("../fonts/Penumbra/l.woff2") format("woff2"),
    url("../fonts/Penumbra/d.woff") format("woff"),
    url("../fonts/Penumbra/a.otf") format("opentype");
    font-style:normal;font-weight:300;
}
.tk-penumbra-sans-std { font-family: "penumbra-sans-std",sans-serif; }

@font-face {
    font-family: 'Lora';
    src: url('../fonts/Lora/Lora-Italic.woff2') format('woff2'),
    url('../fonts/Lora/Lora-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Lora';
    src: url('../fonts/Lora/Lora-Regular.woff2') format('woff2'),
    url('../fonts/Lora/Lora-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lora';
    src: url('../fonts/Lora/Lora-Bold.woff2') format('woff2'),
    url('../fonts/Lora/Lora-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Lora';
    src: url('../fonts/Lora/Lora-BoldItalic.woff2') format('woff2'),
    url('../fonts/Lora/Lora-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

img {
    width: auto;
    height: auto;
}

button {
    border: 0;
    background: none;
    box-shadow: none;
}

@import "general";
@import "plugins/jquery.fancybox.min";
@import "plugins/slick";

@import "elements/popup";
@import "elements/quickbooker";
@import "elements/seatme";
@import "elements/faq";

h1,h2,h3,h4 {
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

iframe {
    @include breakpoint(mobile){
        max-width: 100% !important;
    }
}

.blog-article,
.job-article,
.page-row-blocks {
    ul {
        padding: 0;
        list-style: none;
        li {
            background: url('../img/icon-list.svg') no-repeat 0 0.4em;
            background-size: 0.7em 0.7em;
            padding-left: 1.5em;
        }
    }
}

.container {
    position: relative;

    @media screen and (min-width: 960px){
        width: 90%;
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
        padding-left: 55px;
        padding-right: 55px;
    }

    @media screen and (min-width: $ultimateContainer){
        width: 1650px;
    }

}

.text-center {
    text-align: center;
}
.no-padding .container {
    padding-top: 0 !important;
}
.padding-bottom .container {
    padding-bottom: $pageBlockSpacing;
}
body main>section:last-child .container {
    padding-bottom: $pageBlockSpacing;
    @include breakpoint(tablet){
        padding-bottom: $pageBlockSpacing / 2 !important;
    }
}

/*********************************************************
 NAVIGATIONS
 **********************************************************/
body {
    padding-top: $headerHeight;
    @include breakpoint(mobile){
        padding-top: $headerHeight * 0.75;
    }
    &._menu-open {
        height: 100vh;
        overflow: hidden;
    }
}
.main-header {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    width: 100%;
    z-index: 664;

    .container {
        height: $headerHeight;
        @include breakpoint(mobile){
            height: $headerHeight * 0.75;
        }
    }

    .col-lg-12 {
        .btn-logo {
            float: left;
            position: absolute;
            margin: 0;
            top: 10px;
            width: 220px;
            z-index: 3;
            transition: all .3s ease-out;

            &:hover {
                transform: scale(1.05);
            }

            @media screen and (min-width: 1100px) and (max-width: 1170px){
                width: 150px;
                left: 0px;
            }

            @include breakpoint(mobile){
                width: 160px;
            }

            img {
               width: 100%;
            }
        }

        .location-award {
            float: left;
            position: absolute;
            margin: 0;
            top: 10px;
            width: 80px;
            z-index: 3;
            left: 260px;

            @media screen and (min-width: 767px) and (max-width: 1600px) {
                display: none;
            }

            @media screen and (min-width: 1100px) and (max-width: 1170px){
                width: 100px;
            }

            @include breakpoint(mobile){
                width: 50px;
                left: 190px;
            }

            img {
                width: 100%;
            }
        }

        .greenkey {
            float: left;
            position: absolute;
            margin: 0;
            top: 10px;
            width: 80px;
            z-index: 3;
            left: 370px;
            background-color: #FFF;

            @media screen and (min-width: 767px) and (max-width: 1700px) {
                display: none;
            }

            @media screen and (min-width: 1100px) and (max-width: 1170px){
                width: 100px;
            }

            @include breakpoint(mobile){
                display: none;
            }

            img {
                width: 100%;
            }
        }

        .button-hamburger {
            display: none;
            float: right;
            font: 18px/18px $heading-font-family;
            text-transform: uppercase;
            position: relative;
            padding-left: 40px;
            margin-top: 30px;
            outline: none;

            @include breakpoint(mobile){
                margin-top: 22px;
                font-size: 16px;
            }

            span {
                display: block;
                width: 25px;
                border-top: 2px solid $black;
                border-radius: 2px;
                position: absolute;
                left: 0;
                margin-top: 7px;
                margin-bottom: 7px;
                transition: all .3s ease-out;
                transition-delay: .1s;
                &:before {
                    content: "";
                    border-top: 2px solid $black;
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    top: -8px;
                    left: 0;
                    transition: all .3s ease-out;
                    transition-delay: .1s;
                }
                &:after {
                    content: "";
                    border-top: 2px solid $black;
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    transition: all .1s ease-out;
                }
            }

            &._open {
                span {
                    transform: rotate(45deg);
                    &:before {
                        transform: rotate(-90deg);
                        top: -2px;
                    }
                    &:after {
                        opacity: 0;
                        left: -10px;
                    }
                }
            }

            @media screen and (max-width: 1100px){
                display: block;
            }
        }

        nav {

            /*! mobile navigation */
            @media screen and (max-width: 1100px){
                position: fixed;
                top: $headerHeight;
                left: 105%;
                width: 100%;
                background: white;
                z-index: 2;
                opacity: 0;
                transition: all .3s ease-out;
                height: calc(100% - 80px);
                border-top: 1px solid $grey;
                padding: 50px 0;
                overflow: auto;
            }

            @include breakpoint(mobile){
                top: $headerHeight * 0.75;
                height: calc(100% - 60px);
            }

            @media screen and (min-width: 1100px) {
                margin-right: -40px;
            }

            >ul {
                height: $headerHeight;
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media screen and (max-width: 1100px){
                    display: block;
                    height: auto;
                }

                >li {
                    height: $headerHeight;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;

                    @media screen and (max-width: 1100px){
                        display: block;
                        text-align: center;
                        height: auto;
                    }
                    @media screen and (max-width: 1180px){
                        padding: 0;
                    }
                    @media screen and (max-width: 1490px){
                        padding: 0 10px;
                    }

                    a {
                        padding: 15px 5px;
                        display: block;
                        text-decoration: none;
                        font-size: 14px;
                        font-family: $heading-font-family;
                        text-transform: uppercase;
                        &.active,
                        &:hover {
                            color: $base-color2;
                        }

                        &.has-sub {
                            background: url('../img/icon-angle-down.svg') no-repeat 100% 50%;
                            background-size: 8px 4px;
                            padding-right: 15px;
                            transition: background-position .3s ease-out;
                            &:hover {
                                background-position: 100% 55%;
                            }
                        }

                        @media screen and (max-width: 1100px){
                            display: inline-block;
                        }
                    }

                    /* submenu */
                    ul {
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        list-style: none;
                        padding: 0 20px;
                        background: white;
                        li {
                            opacity: 0;
                            position: relative;
                            left: -10px;

                            a {
                                white-space: nowrap;
                            }

                            &.active {
                                a {
                                    color: $base-color2;
                                }
                            }
                        }
                        @media screen and (max-width: 1100px){
                            position: relative;
                            margin-bottom: 30px;
                        }
                    }
                    &._open {
                        ul {
                            display: block;
                        }
                    }
                    @media screen and (min-width: 1100px) {
                        &:hover {
                            ul {
                                display: block;
                            }
                        }
                    }



                    /*! telephone link */
                    &.telephone {
                        border-left: 1px solid $grey;
                        padding-right: 0;
                        display: flex;

                        @media screen and (max-width: 1330px) {
                            display: none !important;
                        }

                        a {
                            background: url('../img/icon-telephone.svg') no-repeat center left;
                            background-size: 20px 20px;
                            padding-left: 30px;
                        }
                    }
                }
            }
        }
    }
}
body._menu-open {
    .main-header {
        nav {
            left: 0;
            opacity: 1;
        }
    }
}

/*********************************************************
 TITLES OFFSCREEN
 **********************************************************/
.title-left {
    height: 30px;
    position: absolute;
    left: -30px;
    right: auto;
    top: 130px;
    z-index: 3;
    border: 0;
    padding: 0 130px 10px 0;
    font: 14px/14px $heading-font-family;
    transform-origin: 0 0;
    text-transform: uppercase;
    transform: rotate(-90deg);
    overflow: hidden;
    display: none;

    @media screen and (min-width: $ultimateContainer + 50px){
        display: block;
    }
    @media screen and (min-width: 1024px) and (max-width: $ultimateContainer ){
        display: block;
    }

    &:after {
        content: "";
        position: absolute;
        height: 4px;
        width: 100%;
        background: $green;
        bottom: 0;
        left: 0;
    }
    &.go {
        &:after {
            animation: borderSlide 3s;
        }
    }
}
.title-left-reversed {
    top: 110px;
    padding: 0 10px 0 130px;
    left: -30px;
    &:after {
        top: auto;
    }
}
.title-right {
    height: 30px;
    position: absolute;
    left: auto;
    right: -30px;
    top: -130px;
    z-index: 3;
    padding: 10px 0 0 130px;
    font: 14px/14px $heading-font-family;
    text-transform: uppercase;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);
    overflow: hidden;
    display: none;

    @media screen and (min-width: $ultimateContainer + 50px){
        display: block;
    }
    @media screen and (min-width: 1024px) and (max-width: $ultimateContainer ){
        display: block;
    }

    &:after {
        content: "";
        position: absolute;
        height: 4px;
        width: 100%;
        background: $green;
        top: 0;
        left: 0;
    }
    &.go {
        &:after {
            animation: borderSlide 3s;
        }
    }
}
@include keyframes(borderSlide){
    0%{left:0%}
    25%{left:-100%;}
    25.01%{left:105%;}
    50%{left:0%;}
    to{left:0%;}
}

.section-heading-first {
    position: absolute;
    text-align: center;
    color: white;
    margin-top: -100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    cursor: pointer;
    transition: color .3s ease-out;
    text-shadow: 0 0 10px black;

    &:hover {
        color: $base-color2;
    }

    @media screen and (max-width: $tablet){
        display: none;
    }

    &:after {
        content: "";
        position: relative;
        display: block;
        margin: 25px auto;
        width: 4px;
        height: 200px;
        background: $base-color2;
    }
}

/*********************************************************
 HEADERS
 **********************************************************/
.header-container {
    position: relative;
    height: 880px;
    max-height: calc(100vh - 80px);
    font-size: 1em;
    @media screen and (max-width: $laptop){
        height: 550px;
        max-height: none;
    }
    @include breakpoint(mobile){
        margin-bottom: 120px;
        height: 450px;

        &.dashboard-header {
            height: 300px;
        }
    }


    .vimeo-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;
        background: white;
        iframe {
            width: 100vw;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .background {
        background: no-repeat center center;
        background-size: cover;
        position: absolute;
        top: 0; left: 0;
        z-index: 1;
        width: 100%; height: 100%;
        box-shadow: inset 0px 0px 150px $black;
    }
    .header-content {
        position: relative;
        z-index: 3;
        height: 100%;

        @include breakpoint(mobile){
            overflow: hidden;
        }

        h1 {
            font-size: 60px;
            line-height: 60px;
            padding: 0;
            margin: 15px 0;
            color: white;
            text-shadow: 0 0 10px $black;

            @media screen and (max-width: $laptop){
                font-size: 40px;
                line-height: 40px;
            }

            em,
            span {
                font-style: normal;
                display: inline-block;
                padding: 10px;
                background: none;
            }
            br {
                display: block;
            }
            sup,sub {
                font-size: 30px;
                line-height: 35px;
                display: inline-block;
                font-style: normal;
                background: none;
                padding: 10px;
                color: white;
                text-shadow: 0 0 10px $black;

                @include breakpoint(mobile){
                    font-size: 16px;
                    line-height: 25px;
                    letter-spacing: 1px;
                    padding-top: 0;
                    margin-top: -5px;
                    vertical-align: top;
                }
            }
        }
        h2,h3 {
            font-size: 42px;
            line-height: 50px;
            margin: 0;
        }

        .container {
            height: 100%;
            border-color: rgba(255,255,255,0.2);
            color: white;
            display: flex;
            align-items: flex-end;
            @include breakpoint(mobile){
                align-items: center;
            }
            .inner {
                width: 100%;
                padding-bottom: 35px;
                @include breakpoint(mobile){
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-bottom: 15px;
                }
            }

            .header-text-1 {
                text-align: right;
                padding-right: 20px;
                position: absolute;
                top: 110px;
                right: -60px;
                opacity: 0;

                @include breakpoint(mobile){
                    text-align: left;
                    padding-right: 0;
                    position: relative;
                    top: auto;
                }

                h1 {
                    font-size: 60px;
                    line-height: 60px;
                    padding: 0;
                    margin: 15px 0;
                    color: white;
                    background: none;
                    text-shadow: 0 0 10px $black;
                    em,
                    span {
                        font-style: normal;
                        display: inline-block;
                        padding: 10px;
                        color: white;
                        text-shadow: 0 0 10px $black;
                    }

                    @media screen and (max-width: $laptop){
                        font-size: 40px;
                    }

                    @include breakpoint(mobile){
                        font-size: 40px;
                        line-height: 45px;
                        margin-top: 0;
                    }
                    br {
                        display: block;
                    }
                    sup,
                    sub {
                        font-size: 30px;
                        line-height: 35px;
                        display: inline-block;
                        font-style: normal;
                        background: none;
                        padding: 10px;
                        color: white;
                        text-shadow: 0 0 10px $black;


                        @media screen and (max-width: $laptop){
                            font-size: 25px;
                            line-height: 30px;
                        }
                        @include breakpoint(mobile){
                            font-size: 18px;
                            line-height: 25px;
                            display: block;
                        }
                    }
                    sub {
                        margin-bottom: -15px;
                        @include breakpoint(mobile){
                            margin-bottom: -55px;
                            display: block;
                        }
                    }
                }
                p {
                    display: inline-block;
                    padding: 10px;
                    margin-top: -15px;
                    text-shadow: 0 0 10px $black;
                }
            }

            .header-text-2 {
                max-width: 415px;
                position: relative;
                left: -60px;
                opacity: 0;
                text-align: left;
                background: white;
                color: $black;
                padding: 60px 60px 40px;
                margin-bottom: -80px;

                @media screen and (max-width: $laptop){
                    max-width: 325px;
                    padding: 40px;
                }
                @include breakpoint(tablet){
                    max-width: 325px;
                    padding: 40px;
                }
                @include breakpoint(mobile){
                    display: none;
                }
                h1,h2,h3 {
                    font-size: 30px;
                    line-height: 35px;
                    margin: 0;
                    color: $black;
                    @media screen and (max-width: $laptop){
                        font-size: 24px;
                        line-height: 28px;
                    }
                    @include breakpoint(mobile){
                        font-size: 20px;
                        line-height: 25px;
                        margin-bottom: -10px;
                    }

                }
                p {
                    margin: 15px 0 20px 0;
                }
                .button {
                    @extend ._secondary;
                }
                @include breakpoint(mobile){
                    display: none;
                }
            }
        }
    }
}

/*! Custom header height for pages with a form */
.small-header,
.template-wrapper._form {
    .header-container {
        height: 400px;
        max-height: calc(50vh - 80px);
        @include breakpoint(mobile){
            margin-bottom: 0;
        }
        .header-content {
            .inner {
                padding-left: 90px;
                @include breakpoint(tablet){
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
    .section-default._first .container {
        padding-top: 80px;
        @media screen and (max-width: $laptop){
            padding-top: 40px;
        }
    }
}

.template-wrapper._impression {
    .header-container {
        @include breakpoint(tablet){
            margin-bottom: 0;
        }
    }
}

.template-wrapper._content {
    .header-container {
        @include breakpoint(mobile){
            margin-bottom: 0;
        }
    }
    .section-default._first .container {
        padding-top: 80px;

        @media screen and (max-width: $laptop){
            padding-top: 40px;
        }
    }
}

/*********************************************************
 PAGE BLOCKS
 **********************************************************/

main {
    section:first-child {
        position: relative;
    }
}

._text .button {
    margin-top: 15px;
}
.portals {
    position: relative;
    z-index: 4;
    margin: $pageBlockSpacing 0 -15px;

    @include breakpoint(mobile){
        margin-top: $pageBlockSpacing * 1.5;
    }

    .portal {
        background: $grey no-repeat center center;
        background-size: cover;
        position: relative;
        margin: 0 7.5px 15px;
        display: block;
        opacity: 0;

        &._open {
            height: auto !important;
            .side-back {
                display: block;
                position: relative;
                height: auto;
                margin-bottom: 30px;
                opacity: 1;
            }
        }

        .side-front {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            color: white;
            width: 100%;
            padding: 50px 50px 35px;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
            transition: all .3s ease-out;

            // laptop
            @media screen and (max-width: $laptop){
                padding: 30px;
            }

            .title {
                font: 30px/35px $heading-font-family;
                letter-spacing: 0.15em;
                @include breakpoint(tablet){
                    font-size: 25px;
                    line-height: 25px;
                }
            }
            .subitems {
                ul {
                    list-style: none;
                    margin: 1em 0;
                    padding: 0;
                    li {
                        display: inline-block;
                        background: none;
                        padding-left: 0;
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            margin: 0 10px;
                            border-radius: 6px;
                            background: $base-color2;
                        }
                        &:last-child:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .side-back {
            opacity: 0;
            position: absolute;
            z-index: 2;
            height: 100%;
            width: 100%;
            padding: 50px;
            text-align: center;
            background: rgba($black, 0.9);
            color: white;
            transition: all .3s ease-out;

            // laptop
            @media screen and (max-width: $laptop){
                padding: 30px;
            }

            @include breakpoint(tablet){
                display: none;
            }

            .portal-close {
                position: absolute;
                right: 30px;
                top: 38px;
                color: white;
                font-family: $heading-font-family;
                letter-spacing: 0.15em;
                @include breakpoint(tablet){
                    right: 0;
                    top: 20px;
                }
                span {
                    display: block;
                    width: 24px;
                    height: 1px;
                    border-top: 1px solid white;
                    transform: rotate(45deg);
                    &:after {
                        content: "";
                        display: block;
                        width: 24px;
                        height: 1px;
                        border-top: 1px solid #fff;
                        transform: rotate(-90deg);
                        position: absolute;
                        left: 0px;
                        top: -1px;
                    }
                }
            }

            .title {
                color: white;
                font: 30px/30px $heading-font-family;
                padding-bottom: 30px;
                @include breakpoint(tablet){
                    font-size: 25px;
                    line-height: 25px;
                }
                @include breakpoint(mobile){
                    padding-right: 50px;
                    padding-left: 50px;
                }
            }
            .subitems {
                .button {
                    position: relative;
                    opacity: 0;
                    min-width: 175px;
                    max-width: 100%;
                    margin: 0;
                    font-size: 16px;
                    @include breakpoint(tablet){
                        min-width: 0;
                        width: 100%;
                    }
                    &:after {
                        min-width: 135px;
                    }
                }
            }
        }

        @include breakpoint(tablet-and-above){
            &:hover {
                .side-front {
                    opacity: 0;
                }
                .side-back {
                    opacity: 1;
                }
            }
        }
    }

    .column {
        float: left;
        width: 33%;
        @include breakpoint(mobile){
            width: 100%;
            float: none;
        }
    }
    .column-1,
    .column-3 {
        padding-top: 40px;
        @include breakpoint(mobile){
            padding-top: 0;
        }
        .portal {
            height: 600px;
            @include breakpoint(mobile){
                height: 350px;
            }
        }

    }

    .column-2 {
        .portal {
            height: 350px;
            .side-back {
                .title {
                    padding-bottom: 15px;
                }
            }
        }
    }
}
.section-contact {
    .section-heading {
        @extend .title-left;
        @extend .title-left-reversed;
    }
    .container {
        padding-top: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
        }
    }
    .row {
        background: white;
        box-shadow: 0 0 35px rgba($black, 0.06);
        border-bottom: 1px solid $lightBrown;
        margin-bottom: $pageBlockSpacing;
        display: flex;
        >div {
            padding-top: 40px;
            padding-bottom: 30px;
        }
        @include breakpoint(tablet){
            margin-bottom: $pageBlockSpacing / 2;
            .col-md-6 {
                width: 66%;
            }
        }
        @include breakpoint(mobile){
            &:before {
                height: 1px;
                width: 100vw;
                display: block;
                content: "";
                left: 0;
                top: 110px;
                border-top: 1px solid $lightBrown;
                position: absolute;

            }
        }
        @include breakpoint(mobile){
            margin-bottom: 0;
        }



        ._image {
            text-align: center;
            position: relative;
            &:before {
                content: "";
                display: flex;
                height: 100%;
                left: 50%;
                top: 0;
                width: 1px;
                border-left: 1px solid $lightBrown;
                position: absolute;
                z-index: 1;
            }

            img {
                position: relative;
                z-index: 2;
                width: auto;
                @media screen and (max-width: $laptop){
                    max-height: 150px;
                }
            }

            /* mobile images */
            @include breakpoint(mobile){
                position: absolute;
                left: 48%;
                top: 30px;
                &:before {
                    display: none;
                }
                &:first-child {
                    left: 28%;
                }
                img {
                    height: 85px;
                }
            }
        }
        ._text {
            text-align: center;
            font: 1.5em/1.5em $heading-font-family;
            text-transform: uppercase;
            letter-spacing: 0.15em;

            @media screen and (max-width: $laptop){
                font-size: 1em;
                line-height: 1.25em;
            }
            @include breakpoint(mobile){
                padding-top: 130px;
                width: 100%;
            }

            a {
                margin-top: 30px;
                display: inline-block;
                font-size: 28px;
                line-height: 45px;
                text-decoration: none;
                background: url('../img/icon-telephone.svg') no-repeat center left;
                background-size: contain;
                padding-left: 60px;
                transition: color .3s;
                @media screen and (max-width: $laptop){
                    font-size: 22px;
                    line-height: 30px;
                    margin-top: 15px;
                }
                @include breakpoint(mobile){
                    font-size: 18px;
                    line-height: 25px;
                    margin-top: 5px;
                    padding-left: 40px;
                }
                &:hover {
                    color: $lightBrown;
                }
            }
        }
    }

    +.empty-space {
        .container  {
            padding: 0 !important;
            @include breakpoint(mobile){
                padding-top: $pageBlockSpacing / 2 !important;
            }
        }
    }
}
.section-default {
    .container {
        padding-top: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
        }
    }

    &._first {
        .container {
            padding-top: 120px;
        }
        .section-heading-first {
            margin-top: -280px;
        }
    }

    &:not(:first-child) {
        .section-heading {
            @extend .title-left;
        }
    }

    .container>.row {
        position: relative;
        z-index: 2;

        background: white;
        box-shadow: 0 0 35px rgba($black, 0.06);
        display: flex;

        @include breakpoint(tablet-landscape){
            flex-wrap: wrap;
        }

        ._image {
            padding: 0;

            @include breakpoint(tablet){
                float: left;
                width: 100%;
            }

            .image-wrapper {
                height: 100%;
                display: flex;
                @include breakpoint(tablet){
                    height: auto;
                    display: block;
                }

                &._slideshow {
                    display: block;
                }

                >img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                }

                .files-gallery {
                    opacity: 0;
                    visibility: hidden;
                    height: 100%;
                    @include breakpoint(tablet){
                        height: auto;
                        width: 100%;
                        overflow: hidden;
                    }
                    img {
                        object-fit: cover;
                        @include breakpoint(tablet){
                            object-fit: contain;
                        }
                    }

                    .slick-list, .slick-track {
                        height: 100%;
                        @include breakpoint(tablet){
                            height: auto;
                        }
                    }

                    &.slick-initialized {
                        opacity: 1;
                        visibility: visible;
                    }


                    .slick-arrow {
                        position: absolute;
                        bottom: 15px;
                        display: block;
                        box-shadow: none;
                        width: 40px;
                        height: 40px;
                        background: white;
                        border-radius: 5px;
                        font-size: 0;
                        border: 0;
                        outline: none;
                        cursor: pointer;
                        &:hover {
                            background-color: $base-color2;
                            &:before {
                                border-top-color: white;
                                border-right-color: white;
                            }
                        }
                        &:before {
                            content: "";
                            height: 10px;
                            width: 10px;
                            border-top: 1px solid $black;
                            border-right: 1px solid $black;
                            transform: rotate(45deg);
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            margin: -5px -7px;
                        }
                        &.slick-next {
                            right: 15px;
                        }
                        &.slick-prev {
                            z-index: 50;
                            right: 60px;
                            &:before {
                                transform: rotate(-135deg);
                                margin: -5px;
                            }
                        }
                    }
                }
            }
        }

        ._text,
        ._text_text {
            .inner {
                padding: 80px;
                @media screen and (max-width: $laptop){
                    padding: 50px;
                }
                @include breakpoint(tablet){
                    padding: 30px;
                }
                @include breakpoint(mobile){
                    padding: 15px;
                }
                h3 {
                    color: $base-color2;
                    font-size: 18px;
                    line-height: 25px;
                    @media screen and (max-width: $laptop){
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
                h2 {
                    font-size: 32px;
                    line-height: 42px;
                    margin-bottom: 15px;
                    @media screen and (max-width: $laptop){
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
                h3+h1,
                h3+h2 {
                    margin-top: -13px;
                    @media screen and (max-width: $laptop){
                        margin-top: -15px;
                    }
                }

                .button {
                    margin-top: 15px;
                    @extend ._secondary;
                }
            }
        }

        ._text_text {
            .inner:nth-child(1) {
                padding-bottom: 0;
            }
            .inner:nth-child(2) {
                padding-top: 0;
            }
        }
    }
}

.section-video {

    .image-wrapper {
        display: inline-block;
        padding: 65px 65px 0 65px;

        @include breakpoint(tablet){
            padding: 30px 15px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.image-as-background {

    position: relative;

    .container {
        border-left-color: rgba(255,255,255,0.2);
        z-index: 2;
        padding-bottom: 0 !important;
    }

    .background-group {
        z-index: 1;
        width: 100%;
        overflow: hidden;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        @include breakpoint(mobile){
            .container {
                width: 100%;
                padding: 0;
            }
        }
    }

    .section-heading {
        @extend .title-right;
    }

    .background-white,
    .background {
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: -1;
        right: 0;
        top: 0;
        background: no-repeat center center;
        background-size: cover;
        @include breakpoint(mobile){
            left: 0;
            height: 300px;
        }
    }
    .background-white {
        background-color: white;
        left: 0;
        @include breakpoint(mobile){
            display: none;
        }
    }

    .row {
        position: relative;
        z-index: 2;
        padding-top: 240px;
        @include breakpoint(mobile){
            padding-top: 200px;
        }
        ._text {
            padding-right: 5%;
            @include breakpoint(mobile){
                padding-right: 15px;
            }
            .inner {
                background: rgba($black, 0.90);
                color: white;
                padding: 50px 80px;

                @media screen and (max-width: $laptop){
                    padding: 40px 50px;
                }
                * {
                    opacity: 0;
                }

                h3 {
                    color: $base-color2;
                    font-size: 22px;
                    line-height: 25px;
                    @media screen and (max-width: $laptop){
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
                h2 {
                    font-size: 1.9375em;
                    line-height: 1.4em;
                    color: white;
                    margin-bottom: 15px;
                    @media screen and (max-width: $laptop){
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
                h3+h2 {
                    margin-top: -13px;
                }
            }
        }
    }
}
.image-as-background.route {
    .section-heading {
        @extend .title-left;
        @extend .title-left-reversed;
        color: white;
    }
    .row {
        @include breakpoint(desktop){
            padding-top: 230px;
            padding-bottom: 100px;
        }
    }
    ._text {
        .inner {
            background: none;
            text-align: center;
            .button {
                min-width: 150px;
                @extend ._secondary;
                @include breakpoint(tablet-and-above){
                    transform: translateY(-40px);
                }
            }
        }
    }
    @include breakpoint(mobile){
        .background {
            height: 100%;
            background-position: 55% 50%;
        }
    }
}
.usp {
    background: #161616 url('../img/background-usp.jpg') no-repeat center right;
    background-size: auto 100%;
    color: white;

    .container {
        padding-top: $pageBlockSpacing;
        padding-bottom: $pageBlockSpacing;
        border-color: rgba(255,255,255, 0.2);

        .section-heading {
            @extend .title-right;
            color: $black;
        }

        @include breakpoint(tablet){
            .row {
                display: flex;
                h2 {
                    font-size: 16px;
                }
                ul {
                    font-size: 12px;
                }
            }
        }

        @include breakpoint(mobile){
            .row {
                flex-direction: column;
                padding: 0 50px;
                ul {
                    font-size: 14px;
                }
            }
        }

        h2 {
            color: white;
        }

        ul {
            list-style: none;
            margin: 15px 0;
            padding: 0;
            li {
                display: block;
                background: url('../img/icon-check.svg') no-repeat 0px 5px;
                background-size: 13px 10px;
                padding-left: 30px;
                line-height: 26px;
                a {
                    color: white;
                    transition: color .3s;
                    &:hover {
                        color: $base-color2;
                    }
                }
            }
        }
    }
}
.section-blog {
    overflow: visible;
    overflow-x: hidden;

    .container {
        padding-top: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
        }
    }

    .section-heading {
        @extend .title-left;
    }

    .row {
        display: flex;
        @include breakpoint(tablet){
            flex-wrap: wrap;
        }
        ._text:first-child {
            display: flex;
            align-items: center;
            padding-right: 100px;
            .button {
                @extend .outline;
            }
            @include breakpoint(mobile){
                p {
                    display: none;
                }
            }
        }
        @include breakpoint(mobile) {
            ._text:last-child {
                width: 100%;
                padding-right: 0;
                .inner {
                    width: 100%;
                    overflow: auto;

                }
            }
        }
    }

    .blog-list {
        width: 1560px;
        display: flex;
        @include breakpoint(tablet){
            margin-left: -15px;
        }
        @include breakpoint(mobile){
            width: 100%;
        }
        .slick-slide {
            outline: none;
            width: auto;
            max-width: 500px !important;
            @media screen and (min-width: $laptop) {
                .blog {
                    min-height: 830px;
                }
            }
            @include breakpoint(mobile){
                width: 80vw;
            }
        }
        .col-lg-4 {
            opacity: 0;
            transition: all .3s ease;
        }
        &.slick-initialized {
            .col-lg-4 {
                opacity: 1;
            }
        }
        .col-md-4 {
            display: flex;
        }
    }

    .blog-slick-pager {
        position: absolute;
        z-index: 500;
        right: -40px;
        top: -55px;
        width: 35px;
        height: 50px;
        background: url('../img/icon-angle-right.svg') center center no-repeat;
        background-size: 20px 20px;
        cursor: pointer;
        outline: none;
        padding: 0;
        margin: 0;
        &:hover {
            background-image: url('../img/icon-angle-right-black.svg');
        }
        @include breakpoint(mobile){
            right: 5px;
            top: -70px;
        }
        &.blog-slick-prev {
            transform: rotate(180deg);
        }
        &.blog-slick-prev {
            right: 0;
            @include breakpoint(mobile){
                right: 45px;
            }
        }
    }
}
.section-blog-navigation {
    position: relative;

    .section-heading {
        @extend .title-left;
        @extend .title-left-reversed;
    }
    .container {
        padding-top: $pageBlockSpacing;
        padding-bottom: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
            padding-bottom: $pageBlockSpacing / 2;
        }
    }
}
.subscribe {
    background: white;
    font: 22px/22px $heading-font-family;
    text-transform: uppercase;

    @include breakpoint(tablet){
        font-size: 16px;
        line-height: 16px;
    }

    .container {
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        align-items: center;

        @include breakpoint(mobile){
            flex-direction: column;
        }

        .title {
            white-space: nowrap;
            @include breakpoint(mobile){
                margin-bottom: 15px;
            }
        }

        .line {
            width: 100%;
            span {
                margin: 0 50px;
                display: block;
                border-top: 1px solid $grey;
                height: 1px;
            }
            @include breakpoint(mobile){
                margin-bottom: 15px;
            }
        }
        .form {
            justify-content: flex-end;
            width: 100%;
            max-width: 480px;

            form {
                position: relative;

                .input-group-wrapper {
                    position: relative;
                }
                .input-group {
                    margin-bottom: 0;
                    input {
                        margin: 0;
                    }
                }
                .btn {
                    position: absolute;
                    right: 15px;
                    top: 20px;
                    background: url('../img/icon-arrow-right.svg') no-repeat;
                    background-size: contain;
                    width: 20px;
                    height: 13px;
                    border: 0;
                    @media screen and (max-width: $laptop) {
                        top: 15px;
                    }
                }
            }

        }
    }

}
.empty-space {
    .container {
        padding-top: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
        }
    }
}
.section-white {
    background: white;
    &.no-padding {
        margin-top: -$pageBlockSpacing;
        @include breakpoint(tablet){
            margin-top: -($pageBlockSpacing / 2);
        }
    }

    .section-heading {
        @extend .title-left;
        @extend .title-left-reversed;
    }
    .container {
        padding-top: $pageBlockSpacing;
        padding-bottom: $pageBlockSpacing;

        @include breakpoint(mobile){
            padding-top: $pageBlockSpacing / 2;
            padding-bottom: 0;
        }

        ._text {
            opacity: 0;
            @include breakpoint(tablet){
                padding-bottom: 40px;
            }
        }
        .inner {
            padding-left: $pageBlockSpacing;
            padding-right: $pageBlockSpacing;
            @media screen and (max-width: $laptop){
                padding-left: $pageBlockSpacing / 2;
                padding-right: $pageBlockSpacing / 2;
            }
            @include breakpoint(mobile){
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .button {
            @extend ._secondary;
        }
    }

    +section {
        .section-heading {
            color: $black !important;
        }
    }
}
.container-form {
    z-index: 2;
}
.section-form {
    .container {
        padding-top: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
        }
    }
    .row {
        .column:first-child {
            .inner {
                padding: 90px;

                .button {
                    @extend ._secondary;
                }

                @media screen and (min-width: $ultimateContainer){
                    padding-right: 180px;
                }

                @include breakpoint(tablet-landscape){
                    h1,h2 {
                        margin-top: 0;
                    }
                    padding: 30px 30px 50px 30px;
                }
            }
        }
        .column.form {
            @include breakpoint(desktop) {
                margin-top: -140px;
            }
            background: white;
            box-shadow: 0 0 35px rgba($black, 0.06);

            .button {
                @extend ._secondary;
            }

            .inner {
                padding: 80px;
                @include breakpoint(tablet-landscape){
                    padding: 30px;
                }
            }
        }
    }
}
.section-activities {

    &.hide-heading {
        .section-heading {
            display: none;
        }
    }
}
.section-team {
    .container {
        padding-top: $pageBlockSpacing * 2;
        padding-bottom: 0 !important;
        @media screen and (max-width: $tablet){
            padding-top: $pageBlockSpacing;
        }
    }
}
.section-jobs {
    .container {
        padding-top: $pageBlockSpacing * 2;
        @media screen and (max-width: $tablet){
            padding-top: $pageBlockSpacing;
        }
    }
}
.section-blocks {

    .section-heading {
        @extend .title-left;
    }
    .section-heading-first {
        margin-top: -300px;
    }

    .container {
        padding-top: $pageBlockSpacing * 2;

        .row {
            box-shadow: 0 0 30px rgba($black, 0.06);
            display: flex;
            @include breakpoint(tablet-landscape){
                flex-direction: column;
            }
        }
        .column {
            background: white;
            &:nth-child(even){
                background: rgb(250,250,250);
                border-left: 1px solid $grey;
                border-right: 1px solid $grey;
            }
            .inner {
                padding: 40px;
                @include breakpoint(tablet-landscape){
                    text-align: center;
                }
            }
        }
    }
}
.section-images {
    .container {
        padding-top: $pageBlockSpacing;
        @include breakpoint(tablet){
            padding-top: $pageBlockSpacing / 2;
        }
        img {
            height: auto;
        }
    }
}

/*********************************************************
 BLOG
 **********************************************************/
.blog-wrapper {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(tablet){
        margin: 0;
    }

    .col {
        padding-left: 10px;
        padding-right: 10px;
        .blog {
            margin-bottom: 20px;
            @media screen and (min-width: $laptop) {
                min-height: 850px;
            }
        }

    }
    @include breakpoint(desktop){
        ._blog-column-2 {
            margin-top: -50px;
        }
    }
    .blog {
        opacity: 0;
    }
}
.blog {
    background: white;
    box-shadow: 0 0 35px rgba($black, 0.06);

    .blog-image {
        height: 300px;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .6s ease-out;
        }
        @include breakpoint(mobile) {
            height: 200px;
        }
    }
    .blog-body {
        padding: 50px;

        @media screen and (max-width: $laptop){
            padding: 30px;
        }

        .blog-publication {
            font: 20px/20px $heading-font-family;
            padding-bottom: 40px;
            position: relative;
            @media screen and (max-width: $laptop){
                padding-bottom: 20px;
                font-size: 16px;
                line-height: 16px;
            }
            &:after {
                content: "";
                display: block;
                width: 60px;
                height: 2px;
                background: $base-color2;
                position: absolute;
                bottom: -2px;
                left: 0;
            }
        }
        .blog-heading {
            font: 30px/35px $heading-font-family;
            margin: 40px 0;

            @media screen and (max-width: $laptop){
                font-size: 25px;
                line-height: 30px;
                margin: 20px 0;
            }
        }
        .blog-intro {
            .button {
                @extend ._secondary;
            }
        }
    }
}
.blog-article {
    .row {
        @include breakpoint(desktop){
        //@media screen and (min-width: $ultimateContainer){
            max-width: 980px;
            margin: 0 auto;
        }

        &._image {
            margin-top: $pageBlockSpacing;
            margin-bottom: $pageBlockSpacing;
            @include breakpoint(tablet){
                margin-top: $pageBlockSpacing / 2;
                margin-bottom: $pageBlockSpacing / 2;
            }
        }
    }

    .blog-publication {
        font: 20px/20px $heading-font-family;
        padding-bottom: 40px;
        position: relative;
        @media screen and (max-width: $laptop){
            padding-bottom: 20px;
            font-size: 16px;
            line-height: 16px;
        }
        &:after {
            content: "";
            display: block;
            width: 60px;
            height: 2px;
            background: $base-color2;
            position: absolute;
            bottom: -2px;
            left: 0;
        }
    }

    @include breakpoint(mobile){
        margin-top: -100px;
    }
}
.blog-related {

    h2 {
        margin: 0 15px 0;
    }

    .blog-list {
        margin: 50px -10px;
        .col-md-4:nth-child(even){
            margin-top: -50px;
        }
        @include breakpoint(tablet){
            .blog {
                margin-bottom: 20px;
            }
            .col-xs-4 {
                width: 50%;
            }
        }
        @include breakpoint(mobile){
            margin: 30px 0;
            .col-xs-4 {
                width: 100%;
            }
        }
    }
}
.pagination-nav {
    ul {
        list-style: none;
        padding: 0;
        margin: 30px 0;
        li {
            display: inline-block;
            background: none;
            padding: 0;
            a {
                display: block;
                width: 50px;
                height: 50px;
                background: white;
                text-align: center;
                line-height: 50px;
                transition: all .3s ease-out;
                text-decoration: none;
                margin-right: 5px;
                box-shadow: 0 0 35px rgba($black, 0.06);
                &:hover {
                    background-color: $base-color2;
                    color: white;
                }
            }
            &.active a {
                background-color: $base-color2;
                color: white;
            }
        }
    }
}

.event-item {
    position: relative;

    .event-image {
        height: 300px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        z-index: 3;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all .3s ease;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    .event-image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.3);
        z-index: 1;
    }

    .event-body {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%);
        margin: 0;
        color: white;
        font: 30px/35px $heading-font-family;
        display: flex;
        flex-direction: column;
        z-index: 2;

        @media screen and (max-width: $laptop){
            font-size: 25px;
            line-height: 30px;
            margin: 20px 0;
        }

        .event-title {
            font-weight: 900;
        }

        .event-category {
            font-size: 25px;
        }
    }
}

/*********************************************************
 VIDEO BLOCKS
 **********************************************************/
.video {
    opacity: 0;
    position: relative;
    text-align: center;

    .container {
        position: relative;
        border-color: rgba(255,255,255,0.2);
        z-index: 2;
        padding-top: $pageBlockSpacing;
        padding-bottom: $pageBlockSpacing;
    }

    .background-group {
        z-index: 1;
        width: 100%;
        overflow: hidden;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        .background {
            width: 100vw;
            height: 100%;
            position: absolute;
            z-index: -1;
            right: 0;
            top: 0;
            background: no-repeat center center;
            background-size: cover;
        }
    }

    ._text {
        text-align: center;
    }

    .autoplay-video-preview {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.875em;
        display: inline-block;
        letter-spacing: 0.25em;
        font-family: $heading-font-family;
        @media screen and (max-width: $laptop){
            font-size: 1.5em;
        }
        &:before {
            display: block;
            content: "";
            width: 115px;
            height: 115px;
            border-radius: 115px;
            background: white url('../img/icon-play.svg') no-repeat 55% 50%;
            background-size: 30px 40px;
            margin: 0 auto 30px;
            transition: all .3s ease-out;
            @media screen and (max-width: $laptop){
                width: 80px;
                height: 80px;
                background-size: 20px 30px;
            }
        }
        &:hover {
            &:before {
                transform: scale(1.1);
            }
        }
    }

    .section-heading {
        @extend .title-left;
        @extend .title-left-reversed;
        color: $black;
    }

    .video-preview-overlay {
        width: 100%;
        height: 100%;
        min-height: 500px;
        position: absolute;
        z-index: 2;
        top: 0; left: 0;
    }
    .video-preview {
        opacity: 0;
    }
    .video-container {
        position: absolute;
        top: 0; left: 0;
        z-index: 1;
        width: 100%;
        height: 500px;
        overflow: hidden;
    }
}

/*********************************************************
 REFERENCES
 **********************************************************/
.references {
    background: #161616 url('../img/background-usp.jpg') no-repeat center right;
    background-size: auto 100%;
    color: white;
    text-align: center;

    .container {
        padding-top: $pageBlockSpacing;
        padding-bottom: $pageBlockSpacing;
        border-color: rgba(255, 255, 255, 0.2);

        @include breakpoint(mobile){
            padding-top: $pageBlockSpacing / 2;
            padding-bottom: $pageBlockSpacing / 2;
        }

        .section-heading {
            @extend .title-left;
            @extend .title-left-reversed;
            color: $black;
        }

        h2 {
            color: white;
        }
    }
}
.references+section {
    .section-heading {
        color: white;
    }
}
.references-wrapper {

    &:before {
        content: "";
        display: block;
        height: 2px;
        width: 100px;
        border-top: 2px solid $base-color2;
        margin: 30px auto 15px;
    }

    .references-slider {
        max-width: 850px;
        margin: 0 auto;

        .reference-slide {
            outline: none;
        }
    }
    .references-slider-pagination {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;
            li {
                display: inline-block;
                margin: 0 2.5px;
                background: none;
                padding: 0;
                button {
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    border: 2px solid white;
                    font-size: 0;
                    color: transparent;
                    background: none;
                    padding: 0;
                    line-height: 0;
                    box-shadow: none;
                    outline: none;
                    &:hover {
                        border-color: $base-color2;
                    }
                }
                &.slick-active {
                    button {
                        background: white;
                    }
                }
            }
        }
    }
}

.references.companies {
    background: transparent;
    .container {
        border-color: #e0e0df;

        h2 {
            color: $base-color1;
            margin-bottom: 30px;
        }

        .references-slider {
            box-shadow: 0 0 35px rgba(22,22,22,.06);
            margin-top: 50px;
        }
        .reference-slide {
            background: white;
            width: 100%;
            color: $base-color1;
            border-bottom: 1px solid #c8b484;
            .row {
                display: flex;
                margin: 0;
                @include breakpoint(tablet){
                    flex-direction: column;
                }
            }
            .col-md-4 {
                display: flex;
                align-items: center;
            }
            .col-md-8 {
                text-align: left;
                padding: 15px;
                @include breakpoint(tablet){
                    text-align: center;
                    padding: 15px;
                }
                h3 {
                    margin-bottom: 0;
                    @include breakpoint(tablet){
                        margin-top: 0;
                    }
                }
                .author {
                    color: $base-color2;
                    //margin-top: -15px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

/*********************************************************
 ACTIVITIES
 **********************************************************/
.activities-wrapper {
    padding: ($pageBlockSpacing * 1.5) 0 0;

    @include breakpoint(tablet){
        padding-bottom: $pageBlockSpacing / 2;
    }

    @include breakpoint(tablet-and-above){
        ._item-2 {
            margin-top: -50px;
        }
    }
}
.activity {
    display: block;
    * {
        display: block;
    }
    opacity: 0;

    position: relative;
    margin-bottom: 30px;

    .image {
        padding-bottom: 70%;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0; left: 0;
        }
    }
    .activity-body {
        position: absolute;
        z-index: 2;
        bottom: 0; left: 0;
        width: 100%;
        padding: 100px 50px 45px;
        color: white;
        @media screen and (max-width: $laptop){
            padding: 100px 30px 30px;
        }

        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );

        .activity-heading {
            font: 1.875em/1em $heading-font-family;
            text-transform: uppercase;
            letter-spacing: 0.25em;
            transition: all 250ms ease-out;
            margin-bottom: 5px;
            @media screen and (max-width: $laptop){
                font-size: 1.5em;
                line-height: 1.25em;
                letter-spacing: 0.15em;
            }
        }
        .activity-summary {
            display: inline;
            transition: all 250ms ease-out;
            transition-delay: 150ms;
            span {
                display: inline;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    background: $base-color2;
                    border-radius: 5px;
                    margin: 0 7px;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &:hover {
        .activity-body {
            .activity-heading {
                transform: translateY(-10px);
            }
            .activity-summary {
                transform: translateY(-10px);
            }
        }
    }
}
.activity-article {
    @include breakpoint(mobile){
        margin-top: 190px;
    }
}
/*********************************************************
 TEAM
 **********************************************************/
.team-wrapper {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;

    &:after,
    &:before {
        display: none;
    }

    .col {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include breakpoint(desktop){
        ._team-row-2, ._team-row-4 {
            margin-top: -50px;
        }
    }
    @include breakpoint(mobile){
        margin: 40px 0 0 0;
    }
}
.team {

    opacity: 0;
    position: relative;
    color: white;
    margin-bottom: 20px;

    .image {
        padding-bottom: 145%;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .inner {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 30px 30px 15px;
        width: 100%;

        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );

        @include breakpoint(mobile){
            padding: 15px;
        }

        .name {
            font: 1.25em/1.25em $heading-font-family;
            letter-spacing: 0.25em;
            margin-bottom: 5px;
        }
        .function {
            margin-bottom: 15px;
            @include breakpoint(mobile){
                margin-bottom: 5px;
            }
        }
        .email {
            border-top: 1px solid rgba(255,255,255,0.75);
            padding: 15px 50px;
            margin: 0 -50px;
            @include breakpoint(mobile){
                padding: 10px 0 0 0;
                margin: 0;
            }

            a {
                display: inline-block;
                padding-left: 30px;
                background: url('../img/icon-email.svg') no-repeat left center;
                background-size: 20px 16px;
                color: white;
                text-decoration: none;
                &:hover {
                    color: $lightBrown;
                }
                @include breakpoint(mobile){
                    background: none;
                    font-size: 12px;
                    padding: 0;
                }
            }
        }
    }

    .button {
        width: 100%;
        font-size: 12px;
        padding: 10px;
    }

    .team-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.85);
        padding: 30px;
        color: white;
        opacity: 0;
        z-index: -1;
        transition: opacity .3s ease-out;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;


        div {
            position: relative;
            top: -30px;
            opacity: 0;
            transition: opacity .3s ease-out, top .3s ease-out;
            transition-delay: .3s;

            h3 {
                color: white;
                margin-bottom: 5px;
            }
        }
    }

    @include breakpoint(tablet-and-above){
        &.has-content:hover {
            .team-content {
                z-index: 10;
                opacity: 1;
                div {
                    opacity: 1;
                    top: 0;
                }
            }
        }
    }

    &.has-content._opened {
        .team-content {
            z-index: 10;
            opacity: 1;
            div {
                opacity: 1;
                top: 0;
            }
        }
    }
}

/*********************************************************
 JOBS
 **********************************************************/
.jobs-wrapper {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
    &:after,
    &:before {
        display: none;
    }

    .col {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include breakpoint(desktop){
        ._job-row-2 {
            margin-top: -50px;
        }
    }
    @include breakpoint(mobile){
        margin: 40px 0 0;

    }
}
.job-article {
    margin-bottom: $pageBlockSpacing;
    @include breakpoint(mobile){
        margin-top: -($pageBlockSpacing / 2);
        margin-bottom: $pageBlockSpacing / 2;
    }
}
.job {
    background: white;
    opacity: 0;
    box-shadow: 0 0 35px rgba($black, 0.06);
    margin-bottom: 20px;

    .image {
        height: 300px;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.6s ease-out;
        }
    }
    .inner {
        padding: 50px;
        @media screen and (max-width: $laptop){
            padding: 30px;
        }
        .subtitle {
            font: 1.15em/1.15em $heading-font-family;
            text-transform: uppercase;
            letter-spacing: 0.25em;
            padding-bottom: 30px;
            margin-bottom: 30px;
            position: relative;
            @media screen and (max-width: $laptop){
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
            &:after {
                display: block;
                content: "";
                height: 2px;
                width: 65px;
                background: $base-color2;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        .name {
            font: 1.5em/1.25em $heading-font-family;
            text-transform: uppercase;
            letter-spacing: 0.25em;
            margin-bottom: 30px;
            @media screen and (max-width: $laptop){
                margin-bottom: 20px;
            }
        }
        .intro {
            .button {
                clear: both;
                @extend ._secondary;
                margin-top: 30px;
                @media screen and (max-width: $laptop){
                    margin-top: 20px;
                }
            }
        }
    }
}

/*********************************************************
 CONTACTFORM
 **********************************************************/
#sitekick-form-e4da3b7fbbce2345d7772b0674a318d5 {
    .row {
        margin-top: 30px;

        textarea.form-control {
            height: 140px;
            @media screen and (max-width: $laptop) {
                height: 75px;
            }
            @include breakpoint(mobile){
                height: 125px;
            }
        }
    }
}

/*********************************************************
 FOOTER
 **********************************************************/
footer {
    background: $black url('../img/background-footer.jpg') no-repeat right top fixed;
    background-size: cover;
    color: white;

    position: relative;

    .logo {
        position: absolute;
        left: 50%;
        top: -20px;
        width: 125px;
        margin-left: -62.5px;
        img {
            width: 100%;
        }
    }

    .main-footer {

        padding-top: 150px;
        padding-bottom: 50px;
        border-color: rgba(255,255,255,0.20);

        .row {
            &:before, &:after {
                display: none;
            }
        }


        @include breakpoint(mobile){
            .row {
                display: flex;
                flex-direction: column;
                .col-xs-12 {
                    &:nth-child(1) { order: 1; }
                    &:nth-child(2) { order: 3; }
                    &:nth-child(3) { order: 5; }

                    &:nth-child(4) { // contact
                        order: 7;
                    }

                    &:nth-child(5) { order: 2; }
                    &:nth-child(6) { order: 4; }
                    &:nth-child(7) { order: 8; }
                }
            }

        }

        .footer-heading {
            text-transform: uppercase;
            font: 24px/24px $heading-font-family;
            @include breakpoint(mobile){
                font-size: 16px;
                line-height: 16px;
                border-bottom: 1px solid rgba(255,255,255,0.2);
                padding: 15px 30px;
                background: url('../img/icon-angle-down.svg') no-repeat right 15px center;
                background-size: 16px 16px;

            }
        }

        .footer-body {
            padding: 25px 0;

            @include breakpoint(mobile){
                display: none;
                &._open {
                    display: block;
                }
            }

            address {
                font-style: normal;
                @include breakpoint(mobile){
                    padding: 0 30px;
                }
            }
        }

        nav {
            @include breakpoint(mobile){
                padding: 0 15px;
            }
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    padding-left: 20px;
                    background: url('../img/icon-angle-right.svg') no-repeat 0 8px;
                    background-size: 4px 8px;
                    @include breakpoint(mobile){
                        padding-bottom: 5px;
                    }
                }
            }
        }

        .logos {
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            @include breakpoint(mobile){
                margin-top: 30px;
                padding-top: 0;
                text-align: center;
            }


            div {
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 75%;
                margin-bottom: 15px;
            }

        }
    }

    .footer-privacy {
        background: rgba($black, 0.75);

        .container {
            border-color: rgba(255,255,255,0.20);
            padding-top: 20px;

            // quick-booker space
            padding-bottom: 80px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin-right: 15px;
                a {
                    color: rgba(255,255,255, 0.5);
                    text-decoration: underline;
                    font-family: Helvetica, Arial, sans-serif;
                    text-transform: none;
                    &:hover {
                        color: $base-color2;
                    }
                }
            }
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(tablet){
            >div {
                margin-bottom: 20px;
            }
        }
        @include breakpoint(mobile){
            >div {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: white;
        text-decoration: none;
        &:visited {
            color: white;
        }
        &:hover {
            color: $base-color2;
        }
    }

    .footer-social {
        position: absolute;
        width: 50px;
        right: 15px;
        top: 50px;

        @media screen and (min-width: 1280px){
            right: 0;
        }

        @include breakpoint(mobile){
            top: auto;
            bottom: 0;
            width: 100%;
            right: 0;
            display: flex;
            justify-content: center;
            transform: scale(0.7);
        }

        a {
            display: flex;
            width: 30px;
            height: 30px;
            margin: 0 auto 10px;
            text-decoration: none;
            text-align: center;
            align-items: center;
            opacity: 0.75;
            transition: all .3s ease-out;

            &:hover {
                opacity: 1;
            }
            span {
                display: block;
                width: 60%;
                height: 60%;
                margin: 0 auto;
                background: url('../img/icon-facebook.svg') no-repeat center center;
                background-size: contain;
                &.icon-facebook {
                    background-image: url('../img/icon-facebook.svg');
                }
                &.icon-twitter {
                    background-image: url('../img/icon-twitter.svg');
                }
                &.icon-linkedin {
                    background-image: url('../img/icon-linkedin.svg');
                }
                &.icon-instagram {
                    background-image: url('../img/icon-instagram.svg');
                }
            }
        }
    }
}

#cookie_tool_small {
    form {
        text-align: center;
    }
}
#cookie_tool_large {
    .button {
        @extend ._secondary;
    }
}


/*********************************************************
 COMPANIES
 **********************************************************/
.companies {

    .section-heading {
        text-align: center;
        font: 1.5em/1.5em "penumbra-sans-std",Arial,Helvetica,sans-serif;
        text-transform: uppercase;
        letter-spacing: .15em;
        padding: 50px 0;
    }

    .files-gallery {
        display: flex;
        flex-wrap: wrap;
        img {
            width: calc( 25% - 30px);
            margin: 0 15px 30px;
            @include breakpoint(tablet){
                margin: 0 10px 20px;
                width: calc( 33.33% - 20px);
            }
        }
    }
}

/*********************************************************
 EVENTS
 **********************************************************/
.blog._event {
    @media screen and (min-width: $laptop) {
        min-height: 450px;
    }
}

.event-image {
    margin: 30px 0;
    img {
        width: 100%;
    }
}

.event-date {
    background: $lightBrown;
    color: #000;
    padding: 5px 15px;
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    font-family: $heading-font-family;
    position: relative;
    margin-bottom: 30px;
    &:after {
        content: "";
        display: block;
        width: 60px;
        height: 2px;
        background: #73b334;
        position: absolute;
        bottom: -35px;
        left: 0;
    }
}

.form-info {
    p {
        margin-top: 0;
        margin-bottom: 2.5rem;
    }
}



.meeting-form {
    width: 100%;
    border: 0;
    min-height: 55rem;

    @include breakpoint(tablet-landscape) {
        min-height: 60rem;
    }
}

.no-padding {
    .inner {
        padding: 0 !important;
    }
}

/*
* Impression
*/

.impression {
    .container-impression {
        margin-bottom: 50px;
    }

    .section-heading {
        font-size: 22px;
        font-family: $heading-font-family;
        font-weight: $weight-regular;
        margin-bottom: 50px;
    }

    a {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 30px;


    }

    a:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    a img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

#impression-categories {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 9;
    padding: 15px;
    background: #FFF;

    &.impression-categories--fixed {
        position: fixed;
        top: 60px;
    }

    select {
        height: auto;
        background: transparent;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 30px;
        width: 16px;
        height: 16px;
        background: url('../img/icon-arrow-right.svg') no-repeat;
        transform: rotate(90deg) translateX(-50%);
    }

    @include breakpoint(mobile) {
        display: block;
    }
}

.video-column {
    iframe {
        width: 100%!important;
        height: 800px!important;
        min-width: 330px;

        @include breakpoint(tablet-landscape) {
            height: 500px!important;
        }

        @include breakpoint(mobile) {
            height: 300px!important;
        }
    }
}

/**
 * Sitekick settings
 */
@import "elements/sitekick";
