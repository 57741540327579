// Font weights
$weight-light: 			300;
$weight-regular: 		400;
$weight-bold: 			700;

// Font settings
$base-font-family: 		'Lora', Arial, Helvetica, sans-serif;
$base-font-weight: 		$weight-regular;
$base-font-size: 		16px;
$base-font-color1: 		#000000;
$base-font-color2: 		#FFFFFF;

// Headings
$heading-h1-size:		36px;
$heading-h2-size:		30px;
$heading-h3-size:		24px;
$heading-h4-size:		18px;
$heading-h5-size:		14px;
$heading-h6-size:		12px;

$heading-font-family:	"penumbra-sans-std", Arial, Helvetica, sans-serif;
$heading-font-weight: 	$weight-regular;
$heading-font-color1:	#000;
$heading-font-color2:	#333;